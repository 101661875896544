
    import Vue from 'vue'
    import eventBus from 'crossasia-service-core/utils/event_bus'
    import {AppError} from 'crossasia-service-core/models/app_error'

    export default Vue.extend({
        name: 'App',

        data() {
            return {}
        },

        created() {
            eventBus.$on('unauthorized_request', this.handleUnauthorizedRequest)
            eventBus.$on('app_error', this.handleAppError)
            eventBus.$on('sw_update', this.handleSwUpdate)
        },

        beforeDestroy() {
            eventBus.$off('unauthorized_request')
            eventBus.$off('app_error')
            eventBus.$off('sw_update')
        },

        methods: {
            handleUnauthorizedRequest(error: AppError) {
                this.$router.push('/login')
                this.$notify({
                    title: this.$t('auth.session_expired') as string,
                    message: this.$t('auth.need_login') as string
                })
            },
            handleAppError(error: AppError) {
                if (error.message === 'axios.canceled') {
                    return
                }

                this.$notify({
                    title: this.$t('error_title') as string,
                    message:  `${this.$t(error.message)}\n${this.$t(error.detailed)}`,
                    type: 'error',
                    duration: 4000
                })
            },
            handleSwUpdate(registration: any) {
                this.$alert(
                    this.$t('update_needed_detailed') as string,
                    this.$t('update_needed') as string, {
                        confirmButtonText: 'OK',
                        callback: () => {
                            if (!registration.waiting) {
                                return
                            }
                            registration.waiting.postMessage('skipWaiting')
                        }
                    })
            }
        },
    })
