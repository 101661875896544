import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import app from './app'
import shipment from './shipment'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: process.env.VUE_APP_ENV !== 'production',
    plugins: [
        createPersistedState({
            key: 'clientServiceStore',
        })],
    modules: {
        app,
        user,
        shipment
    }
})

export default store