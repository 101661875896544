import Vue from 'vue'
import Vuex, {ActionContext, ActionTree, GetterTree, MutationTree} from 'vuex'

Vue.use(Vuex)

export class State {
    public shipmentListTab: string = ''
}

const getters = <GetterTree<State, any>> {
    shipmentListTab(state: State): string {
        return state.shipmentListTab
    }
}

const mutations = <MutationTree<State>> {
    SelectShipmentListTab(state: State, tab: string) {
        state.shipmentListTab = tab
    }
}

const actions = <ActionTree<State, any>> {
    SelectShipmentListTab(store: ActionContext<State, any>, tab: string) {
        store.commit('SelectShipmentListTab', tab)
    }
}

const shipment = {
    state: new State(),
    getters,
    mutations,
    actions
}

export default shipment
