console.log(`Base url: ${process.env.VUE_APP_BASE_URL}`)

export const APP_VERSION = () => {
    return __APP_VERSION__
}

const config = {
    clientSrvApiUrl: process.env.VUE_APP_BASE_URL || '',
    authSrvApiUrl: process.env.VUE_APP_BASE_URL || '',
    publicSrvApiUrl: process.env.VUE_APP_BASE_URL || '',
    authTokenKey: 'auth_token',
}

export default config