import Vue from 'vue'
import Router from 'vue-router'
import VueRouter from 'vue-router'
import Auth from '../backend/auth'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faColumns} from '@fortawesome/free-solid-svg-icons/faColumns'
import {faFileInvoiceDollar} from '@fortawesome/free-solid-svg-icons/faFileInvoiceDollar'
import {faBoxes} from '@fortawesome/free-solid-svg-icons/faBoxes'
import {faTruck} from '@fortawesome/free-solid-svg-icons/faTruck'
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser'
import i18n from '../i18n'

Vue.use(Router)
library.add(faColumns)
library.add(faFileInvoiceDollar)
library.add(faBoxes)
library.add(faTruck)
library.add(faUser)

export const routes = [
    {
        path: '',
        component: () => import('../views/layout/Layout.vue'),
        redirect: '/dashboard',
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('../views/Dashboard.vue'),
            meta: {title: 'dashboard', noCache: true, icon: faColumns}
        }]
    },
    {
        path: '/shipments',
        component: () => import('../views/layout/Layout.vue'),
        // redirect: '/shipments',
        children: [{
            path: '/shipments',
            name: 'shipmentList',
            component: () => import('../views/ShipmentList.vue'),
            meta: {title: 'shipmentList', noCache: true, icon: faTruck}
        }]
    },
    {
        path: '/shipments/:code',
        component: () => import('../views/layout/Layout.vue'),
        // redirect: '/shipments/:code',
        hidden: true,
        children: [{
            path: '/shipments/:code',
            name: 'shipmentForm',
            component: () => import('../views/ShipmentForm.vue'),
            meta: {title: 'shipmentForm', dynamic: '', noCache: true, icon: faTruck}
        }]
    },
    {
        path: '/entries',
        component: () => import('../views/layout/Layout.vue'),
        children: [{
            path: '/entries',
            name: 'entryList',
            component: () => import('../views/EntryList.vue'),
            meta: {title: 'entryList', noCache: true, icon: faBoxes}
        }]
    },
    {
        path: '/entries/:id',
        component: () => import('../views/layout/Layout.vue'),
        hidden: true,
        children: [{
            path: '/entries/:id',
            name: 'entryForm',
            component: () => import('../views/EntryForm.vue'),
            meta: {title: 'entryForm', dynamic: '', noCache: true, icon: faBoxes}
        }]
    },
    {
        path: '/transactions',
        component: () => import('../views/layout/Layout.vue'),
        children: [{
            path: '/transactions',
            name: 'transactionList',
            component: () => import('../views/TransactionList.vue'),
            meta: {title: 'transactionList', noCache: true, icon: faFileInvoiceDollar}
        }]
    },
    {
        path: '/transactions/:id',
        component: () => import('../views/layout/Layout.vue'),
        hidden: true,
        children: [{
            path: '/transactions/:id',
            name: 'transactionForm',
            component: () => import('../views/TransactionForm.vue'),
            meta: {title: 'transactionForm', dynamic: '', noCache: true, icon: faFileInvoiceDollar}
        }]
    },
    {
        path: '/myaccount',
        component: () => import('../views/layout/Layout.vue'),
        children: [{
            path: '/myaccount',
            name: 'my_account',
            component: () => import('../views/MyAccount.vue'),
            meta: {title: 'my_account', noCache: true, icon: faUser}
        }]
    },
    {path: '/login', name: 'login', component: () => import('../views/Login.vue'), meta: {title: 'login', noAuth: true}},
    {path: '/restorePassword', name: 'restorePassword', component: () => import('../views/RestorePassword.vue'), meta: {title: 'restorePassword', noAuth: true}},
    {path: '*', name: 'notFound', redirect: '/'},
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = i18n.t('route.' + to.meta?.title).toString() + (to.meta?.dynamic ? ` | ${to.meta.dynamic}` : '')
    })
})

router.beforeEach((route, redirect, next) => {
    if (!Auth.loggedIn() && !route.meta?.noAuth) {
        next({
            path: '/login',
            query: {redirect: route.fullPath}
        })
    } else {
        next()
    }
})

export default router