import Vue from 'vue'
import {format} from 'date-fns'
import zh from 'date-fns/locale/zh_cn/index'
import ru from 'date-fns/locale/ru/index'
import store from '../store/index'

const dateLocale = () => {
    switch (store.getters.language) {
        case 'ru':
            return ru
        case 'zh':
            return zh
        default:
            return ru
    }
}

Vue.filter('parseDate', (date?: string) => {
    if (date) {
        return format(date, 'D MMM YYYY', {locale: dateLocale()})
    }
    return ''
})

Vue.filter('parseTimestamp', (date?: string) => {
    if (date) {
        return format(date, 'DD.MM.YYYY HH:mm:ss', {locale: dateLocale()})
    }
    return ''
})

Vue.filter('toKG', (val?: string) => {
    if (val) {
        return `${parseFloat(val).toFixed(2)} кг`
    }
    return ''
})

Vue.filter('toM3', (val?: number) => {
    if (val) {
        return `${val} м<sup><small>3</small></sup>`
    }
    return ''
})

Vue.filter('toDimension', (length?: string, height?: string, width?: string) => {
    if (length && height && width) {
        return `${length}&#xD7;${height}&#xD7;${width}`
    }
    return ''
})

Vue.filter('toFixed', (val?: string, fraction?: number) => {
    if (val) {
        return parseFloat(val).toFixed(fraction).toString()
    }
    return ''
})
