import {ActionContext, ActionTree, GetterTree, MutationTree} from 'vuex'
import Auth from '../backend/auth'

export class State {
    public username: string = ''
}

const getters = <GetterTree<State, any>> {
    username(state: State): string {
        return state.username
    }
}

const mutations = <MutationTree<State>> {
    SET_USERNAME(state: State, username: string) {
        state.username = username
    }
}

const actions = <ActionTree<State, any>> {
    Login(store: ActionContext<State, any>, credentials: any) {
        return new Promise((resolve, reject) => {
            Auth.login(credentials.username, credentials.password).then((response) => {
                Auth.setToken(response.token)
                store.commit('SET_USERNAME', credentials.username)
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    Logout(store: ActionContext<State, any>) {
        return new Promise((resolve, reject) => {
            Auth.logout().then((response) => {
                Auth.deleteToken()
                store.commit('SET_USERNAME', '')
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

const user = {
    state: new State(),
    getters,
    mutations,
    actions
}

export default user