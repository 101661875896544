import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/index'
import i18n from './i18n'
import './filters/index'
import {
    Pagination,
    Dialog,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    InputNumber,
    Select,
    Option,
    OptionGroup,
    Button,
    ButtonGroup,
    Table,
    TableColumn,
    Popover,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Row,
    Col,
    Collapse,
    CollapseItem,
    Container,
    Header,
    Main,
    Footer,
    Tooltip,
    Loading,
    MessageBox,
    Message,
    Notification
} from 'element-ui'
import ScrollBar from 'element-ui/lib/scrollbar.js'
import ElementLocale from 'element-ui/lib/locale'
import VueLazyLoad from 'vue-lazyload'
import 'tachyons/css/tachyons.css'
import 'viewerjs/dist/viewer.css'
import '@/style/index.scss'
import VueViewer from 'v-viewer'

Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Popover)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Row)
Vue.use(Col)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(ScrollBar)
Vue.use(Tooltip)

Vue.use(Loading.directive as any)
Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

ElementLocale.i18n((key: any, value: any) => i18n.t(key, value))

Vue.config.productionTip = false

Vue.use(VueLazyLoad, {
    attempt: 1,
    error: '/img/error.svg',
    loading: '/img/loading.svg'
})

Vue.use(VueViewer, {
    defaultOptions: {
        zIndex: 9999,
    }
})

Vue.mixin({
    computed: {
        appVersion: () => {
            return __APP_VERSION__
        }
    }
})

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
